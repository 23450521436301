export const SET_RESOURCE_TOOLS = (state, tool) => {
  state.tools = tool;
};

export const SET_RESOURCE_TOOL = (state, tool) => {
  state.tool = tool;
};

export const SET_RESOURCE_MESSAGE = (state, message) => {
  state.message = message;
};