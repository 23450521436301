import Schedule from "@/api/Schedule";
import Document from "@/api/Document";
import { useToast } from "vue-toast-notification";
import router from "@/router/router";
import socket from "@/utils/event-bus";
import { events } from "@/utils/constants";

const toast = useToast();
// import router from "@/router/router";

export const TimeSlotsAction = ({ commit, state }) => {
	if (state?.time_slots?.length === 0) {
		Schedule.TimeSlotsApi()
			.then((response) => {
				commit("SET_SCHEDULE_TIME_SLOTS", response.data);
			})
			.catch((error) => {
				if (error.response.status == 401 || error.response.status == 404) {
					commit("SET_SCHEDULE_TIME_SLOTS", null);
					toast.error(`${error.response.data.errors.root}`);
				}
			});
	}
};

export const PaymentGatewaysAction = ({ commit, state }) => {
	if (state.payment_gateways.length === 0) {
		Schedule.PaymentGatewaysApi()
			.then((response) => {
				commit("SET_PAYMENT_GATEWAYS", response.data.data);
			})
			.catch((error) => {
				if (error) {
					commit("SET_PAYMENT_GATEWAYS", []);
				}
			});
	}
};

export const CountriesAction = ({ commit }) => {
	// if (state?.time_slots?.length === 0) {
	return Schedule.CountriesApi()
		.then((response) => {
			commit("SET_SCHEDULE_COUNTRY", response.data.data);
			return response.data.data[0].id;
		})
		.catch((error) => {
			if (error.response.status == 401 || error.response.status == 404) {
				commit("SET_SCHEDULE_COUNTRY", []);
				toast.error(`${error.response.data.errors.root}`);
			}
			return false;
		});
	// }
};

export const StatesAction = ({ commit }, country_id) => {
	// if (state?.time_slots?.length === 0) {
	Schedule.StatesApi(country_id)
		.then((response) => {
			commit("SET_SCHEDULE_STATES", response.data.data);
		})
		.catch((error) => {
			if (error.response.status == 401 || error.response.status == 404) {
				commit("SET_SCHEDULE_STATES", []);
				toast.error(`${error.response.data.errors.root}`);
			}
		});
	// }
};

export const clearScheduleVirtualSession = ({ commit }) =>
	commit("SET_SCHEDULE_DETAILS", {});

export const ScheduleVirtualSessionAction = ({ commit, getters }, formData) => {
	commit("SET_SCHEDULE_DETAILS_LOADER", true);
	let documentTitle = formData.title;
	let entryPoint = formData.entry_point;

	Document.storeFileUpload({ title: documentTitle, entry_point: entryPoint })
		.then((response) => {
			commit("SET_SCHEDULE_DETAILS_LOADER", false);
			commit("SET_INITIALIZED_DOCUMENT_ID", response.data.data.id);
		})
		.then(() => {
			const newFormData = {
				...formData,
				parent_id: getters.initializedDocumentId,
			};

			if (newFormData.type == "Upload") {
				const files = newFormData.files.map((item) => ({
					...item,
					parent_id: newFormData.parent_id,
				}));
				newFormData.files = files;
			}

			Schedule.ScheduleVirtualSessionApi(newFormData)
				.then((response) => {
					commit("SET_SCHEDULE_DETAILS", response.data.data);
				})
				.catch((error) => {
					commit("SET_SCHEDULE_DETAILS_LOADER", false);
					commit("SET_SCHEDULE_DETAILS", null);
					toast.error(`${error.response.data.message}`);
				});
		});
};

// for notary request payment
export const ScheduleTransactionAction = (
	{ commit, state },
	payment_gateway
) => {
	Schedule.ScheduleTransactionApi({
		id: state.schedule_details.transactions[0].id,
		payment_gateway,
	})
		.then(() => {
			commit("transactions/SET_SCHEDULE_TRANSACTION_STATUS", true, {
				root: true,
			});
			toast.success("Congratulations, your transaction was successful");
		})
		.catch((error) => {
			if (error.response.status == 401 || error.response.status == 404) {
				commit("transactions/SET_SCHEDULE_TRANSACTION_STATUS", false, {
					root: true,
				});
				toast.error(`${error.response.data.errors.root}`);
			}
		});
};

// agora
export const GenAgoraTokenAction = ({ commit }, payload) => {
	return Schedule.GenerateAgoraToken(payload)
		.then((response) => {
			commit("SET_AGORA_DETAILS", true);
			window.sessionStorage.setItem(
				"agora",
				JSON.stringify(response.data.data)
			);
			return response;
		})
		.catch((error) => {
			if (error) sessionStorage.setItem("agora", null);
			return false;
		});
};

// get VIRTUAL SESSION details
export const VirtualSessionDetailsAction = ({ commit }, id) => {
	console.log("id", id);
	commit("SET_VIRTUAL_SESSION_LOADER", true);
	return Schedule.VirtualSessionDetailsApi(id)
		.then((response) => {
			console.log("SET_VIRTUAL_SESSION_DETAILS", response.data.data);
			commit("SET_VIRTUAL_SESSION_DETAILS", response.data.data);
			commit("SET_VIRTUAL_SESSION_LOADER", false);
		})
		.catch(() => {
			commit("SET_VIRTUAL_SESSION_DETAILS", null);
			commit("SET_VIRTUAL_SESSION_LOADER", false);
		});
};

export const clearMonetaryValue = ({ commit }) =>
	commit("SET_MONETARY_VALUE", false);

// UPDATE MONETARY VALUE
export const documentWithMonetaryValue = ({ commit }, formData) => {
	// if (!formData.data.has_monetary_value) {
	// 	commit('SET_MONETARY_VALUE', false);
	// 	return;
	// }

	return Schedule.VirtualSessionMonetaryValue(formData)
		.then((response) => {
			const docId = router.currentRoute.value.params.document_id;

			Document.showDocument(docId).then((response) => {
				commit("SET_DOCUMENT", response.data.data);
				socket.emit(
					events.UPDATE_DOCUMENT_DISPLAYED,
					JSON.stringify(response.data.data)
				);
			});

			commit("SET_MONETARY_VALUE", response.data.data.has_monetary_value);
			commit("SET_VIRTUAL_SESSION_DETAILS", response.data.data);

			if (response.data.data.has_monetary_value)
				toast.success("Document(s) has monetary value");
		})
		.catch((error) => {
			if (error.response.data.data.code == 409) {
				toast.error(`${error.response.data.data.error}`, {
					timeout: 5000,
					position: "top-right",
				});
			}
		});
};
