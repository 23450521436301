export default {
	documents: [],
	documentAuditTrail: {},
	statistics: {},
	documentsByStatus: [],
	document: [],
	doneEditing: null,
	resourceTools: [],
	isOpenModal: false,
	guest: { openGuestModal: false },
	confirmGuest: false,
	feedback: false,
	isDoneLoading: false,
	message: null,
	cancel: false,
	isLoading: false,
	isDocLoading: false,
	isToolLoading: false,
	completedDoc: null,
	audit_trails: [],
	audit_trails_loader: false,

	session_complete: false,
	shareDocLoader: false,

	documentVerifySignersLoader: false,
	auditTrailUpdateLoader: false,
	initializedDocumentId: null,
};

