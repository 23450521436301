export const unslugify = (slug) => {
  if (slug.length === 0) {
    return;
  }
  const result = slug.replace(/-/g, " ");
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const NAME_REGEX = /^[a-zA-Z -]{3,50}$/;
export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const PHONE_REGEX = /^234[0-9]{10}$/;

export const slugify = (str) => {
  if (str.length === 0) {
    return;
  }
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const randomId = (leng) => {
  if (!leng) {
    return;
  }
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < leng; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randomNumber = (leng) => {
  if (!leng) {
    return;
  }
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < leng; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// generate a dynamic uid for agora
export const generateUid = (string) => {
  if (string.length === 0) {
    return;
  }
  return slugify(string) + "#" + randomId(5);
};

// generate display name from generated uid
export const generateName = (string) => {
  if (string.length === 0) {
    return;
  }
  let unslug = unslugify(string);
  return unslug.split("#")[0];
};

export const envFunction = (devEnv, stagingEnv, liveEnv,demoEnv) => {
  const domainName = window.location.hostname;
  return domainName.includes("dev") || process.env.VUE_APP_ENVIRONMENT === "development"
    ? devEnv
    : domainName.includes("staging")
    ? stagingEnv
    : domainName.includes("demo")
    ? demoEnv
    : liveEnv;
};

// check device os
export const platformInitiated = () => {
  let mobilePlatforms = [
    "iPhone",
    "iPod",
    "iPad",
    "Android",
    "BlackBerry",
    "Opera",
  ];
  let typeOfOS = window.navigator.platform;
  if (mobilePlatforms.includes(typeOfOS)) {
    return "Mobile";
  } else {
    return "Web";
  }
};

const log = console.log.bind(console);

const removeDiv = function (selector) {
  let element = document.getElementById(selector);
  if (element === null) {
    return null;
  } else {
    element.remove();
  }
};

export { log, removeDiv };
