export default {
  schedule_formdata: {
    title: null,
    description: null,
    TemplateType: null,
    documentTitle: null,
    documentId: null,
    documentFile: [],
    participants: [],
    schedule_type: null, //immediate or schedule
    notary: null, // yes or no
    date: null,
    time: null,

    // delivery_option: null,
    // address: null,
  },
};
