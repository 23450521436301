import Document from '@/api/Document';
import Tool from '@/api/Tool';
import Print from '@/api/Print';
import router from '@/router/router';
import store from '@/store';
import { useToast } from 'vue-toast-notification';
import socket from '@/utils/event-bus';
import { events } from '@/utils/constants';

const toast = useToast();

export const getUserDocuments = ({ commit }, token) => {
	Document.allDocuments(token)
		.then((response) => commit('SET_DOCUMENTS', response.data))
		.catch((error) => {
			if (error.response.status === 401 || error.response.status == 422) {
				commit('SET_TOKEN', null);
				router.push({ name: 'Login' });
			}
		});
};

export const getAuditTrail = ({ commit }, formData) => {
	Document.auditTrail(formData).then((response) => {
		commit('SET_AUDIT_TRAIL', response.data.data);
	});
};

export const getStatistics = ({ commit }, token) => {
	Document.documentStatistics(token).then((response) => commit('SET_DOCUMENT_STATISTICS', response.data));
};

export const getUserDocumentByStatus = ({ commit }, formData) => {
	Document.allDocumentByStatus(formData).then((response) => {
		if (!['Received', 'Deleted'].includes(formData)) {
			commit('SET_DOCUMENTS_BY_STATUS', response.data.data);
		}
	});
};

export const getReceivedDocuments = ({ commit }, token) => {
	Document.allReceivedDocuments(token).then((response) => commit('SET_DOCUMENTS_BY_STATUS', response.data.data));
};

export const getDeletedDocuments = ({ commit }, token) => {
	Document.allDeletedDocuments(token).then((response) => commit('SET_DOCUMENTS_BY_STATUS', response.data.data));
};

export const finishAnnotation = ({ commit }, formData) => {
	Document.allCompletedDocuments(formData).then((response) => {
		commit('SET_MESSAGE', response.data.data);
	});
};

export const getUserDocument = ({ commit }, docId) => {
	commit('SET_DOCUMENT_LOADER', true);
	Document.showDocument(docId)
		.then((response) => commit('SET_DOCUMENT', response.data.data))
		.then(() => setTimeout(() => commit('SET_DOCUMENT_LOADER', false), 1000))
		.catch((error) => {
			commit('SET_DOCUMENT_LOADER', false);
			if (error.response.status === 401 || error.response.status == 422) {
				commit('SET_TOKEN', null);
				router.push({ name: 'Login' });
			}
		});
};

export const fileUploads = ({ commit }, formData) => {
	// Document.storeFileUpload(formData)
	Document.storeUploadConvert(formData)
		.then((response) => {
			commit('SET_DOCUMENT', response.data.data);
			commit('SET_CANCEL', true);
			router.push({ name: 'document.prepare' });
		})
		.catch((error) => {
			toast.error(`${error.response.data.data.error}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const moreFileUploads = ({ commit }, formData) => {
	commit('SET_LOADER', true);
	Document.storeFileUpload(formData)
		.then((response) => {
			Document.showDocument(response.data.data.id).then((response) => {
				commit('SET_DOCUMENT', response.data.data);
				socket.emit(events.UPDATE_DOCUMENT_DISPLAYED, JSON.stringify(response.data.data));
				commit('SET_LOADER', false);
			});
		})
		.catch((error) => {
			commit('SET_LOADER', false);
			if (error.response.status == 422) {
				toast.error(`${error.response.data.message}`, {
					timeout: 5000,
					position: 'top-right',
				});
			}
		});
};

export const removeRecentUpload = ({ commit }) => commit('SET_CANCEL', false);

export const removeDocument = ({ commit }, formData) => {
	Document.deleteDocument(formData)
		.then((response) => {
			if (router.currentRoute.value.name === 'document.edit') {
				Document.showDocument(router.currentRoute.value.params.document_id).then((response) => {
					commit('SET_DOCUMENT', response.data.data);
					socket.emit(events.UPDATE_DOCUMENT_DISPLAYED, JSON.stringify(response.data.data));
				});
			} else {
				const token = store.getters['auth/token'];
				const status = router.currentRoute.value.query.status;

				if (status != undefined) {
					const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);

					Document.documentStatistics(token).then((response) => commit('SET_DOCUMENT_STATISTICS', response.data));

					if (!['Received', 'Deleted'].includes(capitalizedStatus)) {
						Document.allDocumentByStatus(capitalizedStatus).then((response) =>
							commit('SET_DOCUMENTS_BY_STATUS', response.data.data)
						);
					} else {
						if (capitalizedStatus == 'Received') {
							Document.allReceivedDocuments(token).then((response) =>
								commit('SET_DOCUMENTS_BY_STATUS', response.data.data)
							);
						}
						if (capitalizedStatus == 'Deleted') {
							Document.allDeletedDocuments(token).then((response) =>
								commit('SET_DOCUMENTS_BY_STATUS', response.data.data)
							);
						}
					}
				}
			}

			toast.success(`${response.data.data.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const retrieveDocument = ({ commit }, formData) => {
	Document.restoreDocument(formData)
		.then((response) => {
			const token = store.getters['auth/token'];
			Document.allDeletedDocuments(token)
				.then((response) => commit('SET_DOCUMENTS_BY_STATUS', response.data.data))
				.then(() => {
					Document.documentStatistics(token).then((response) => commit('SET_DOCUMENT_STATISTICS', response.data));
				});

			toast.success(`${response.data.data.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const addSelf = ({ commit }, formData) => {
	Document.storeSelf(formData)
		.then((response) => {
			commit('SET_DOCUMENT', response.data.data);

			toast.success('You added yourself as a signer', {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.response.data.data.error}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const addParticipant = ({ commit, getters }, formData) => {
	const docId = getters.userDocument.id;
	Document.addParticipantsOnCall(formData)
		.then((response) => {
			Document.showDocument(docId).then((response) => {
				socket.emit(events.DOC_OWNER_INVITE_PARTICIPANTS, null);
				commit('SET_DOCUMENT', response.data.data);
			});

			toast.success(`${response.data.data.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const isGuest = ({ commit }, formData) => {
	commit('SET_GUEST', formData);
	commit('SET_CONFIRM_GUEST', false);
};

export const shareDocument = ({ commit }, formData) => {
	commit('SHARE_DOC_LOADER', true);
	return Document.shareDoc(formData[0]?.document_id, formData)
		.then(() => {
			commit('SHARE_DOC_LOADER', false);
			toast.success(`Document has been shared successfully`, {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			commit('SHARE_DOC_LOADER', false);
			toast.error(`${error.response.data.data.error}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const renderDocument = ({ commit }, docId) => {
	return Document.showDocument(docId)
		.then((response) => commit('SET_DOCUMENT', response.data.data))
		.catch((error) => {
			if (error.response.status === 401 || error.response.status == 422) {
				('');
			}
		});
};

export const doneEditing = ({ commit, getters }, formData) => {
	const docId = getters?.userDocument?.id;
	commit('SET_DONE_LOADER', true);

	// Document.storeUploadConvert(formData)
	return Document.completedDoc(formData)
		.then((response) => {
			commit('SET_DONE_LOADER', false);
			commit('SET_DOCUMENT_DONE', response.data.data);
			commit('SET_SESSION_COMPLETE', true);
			commit('SET_COMPLETED_DOCUMENT', response.data.data);
			socket.emit(events.NOTARY_COMPLETE_SESSION);
			if (formData.redirect) window.location.href = `/session-complete/${docId}`;
			return true;
		})
		.catch((error) => {
			commit('SET_DONE_LOADER', false);
			toast.error('Error, unable to process the document.', {
				timeout: 5000,
				position: 'top-right',
			});
			if (error.response.status == 409) {
				toast.error(`${error.response.data.data.error}`, {
					timeout: 5000,
					position: 'top-right',
				});
			}
			return false;
		});
};

export const editParticipant = ({ commit }, formData) => {
	Document.updateParticipant({ participants: formData })
		.then((response) => {
			commit('SET_DOCUMENT', response.data.data);

			toast.success('Participant updated successfully', {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const removeNotification = ({ commit }, closeModal) => commit('SET_NOTIFICATION', closeModal);

export const invitationMail = ({ commit }, formData) => {
	Document.mailToParticipant({
		participants: formData,
		subject: formData.subject,
		message: formData.message,
		files: formData.files,
	})
		.then((response) => {
			commit('SET_DOCUMENT', response.data.data);
			commit('SET_NOTIFICATION', true);
		})
		.catch((error) => {
			if (error.response.status == 422) {
				toast.error('Kindly add participant(s)', {
					timeout: 5000,
					position: 'top-right',
				});
			}
		});
};

export const sendFeedback = ({ commit }, formData) => {
	Document.feedback({ formData })
		.then((response) => {
			commit('SET_FEEDBACK', true);
			toast.success(`${response.data.data.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const removeParticipant = ({ commit }, formData) => {
	Document.deleteParticipant(formData)
		.then((response) => {
			commit('SET_DOCUMENT', response.data.data);

			toast.success('Participant removed successfully', {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			toast.error(`${error.message}`, {
				timeout: 5000,
				position: 'top-right',
			});
		});
};

export const getTools = ({ commit }, formData) => {
	commit('SET_LOADER', true);
	Tool.all(formData)
		.then((response) => {
			commit('SET_RESOURCE_TOOLS', response.data.data);
			commit('SET_LOADER', false);
		})
		.then(() => commit('SET_LOADER', false));
};

export const resourceTools = ({ commit, getters }, formData) => {
	commit('SET_LOADER', true);
	Document.storeTools(formData)
		.then(() => {
			Tool.all(getters.userDocument.id).then((response) => {
				commit('SET_RESOURCE_TOOLS', response.data.data);
				commit('SET_LOADER', false);
				// socket.emit(
				//   events.NOTARY_SEND_TOOLS,
				//   JSON.stringify(response.data.data)
				// );
			});
		})
		.catch(() => {
			commit('SET_LOADER', false);
		});
};

export const editTools = ({ commit }, formData) => {
	if (['Signature', 'Initial', 'Seal', 'Stamp', 'Photograph'].includes(formData.payload.tool_name)) {
		if (!['drag', 'resize'].includes(formData.action)) {
			commit('SET_TOOL_LOADER', { id: formData.id, active: true });
		}
	}

	Document.updateTool(formData.id, formData.payload)
		.then((response) => {
			socket.emit(events.NOTARY_EDIT_TOOLS, JSON.stringify(response.data.data));
			const docId = router.currentRoute.value.params.document_id;
			Document.showDocument(docId).then((response) => {
				commit('SET_DOCUMENT', response.data.data);
				if (formData.payload.tool_name == 'Seal') {
					socket.emit(events.UPDATE_DOCUMENT_DISPLAYED, JSON.stringify(response.data.data));
				}
			});

			if (formData.payload.tool_name) {
				commit('SET_EDIT_RESOURCE_TOOL', response.data.data);
				setTimeout(() => {
					commit('SET_TOOL_LOADER', { id: '', active: false });
				}, 500);
			} else {
				if (formData.payload.value != '') commit('SET_EDIT_RESOURCE_TOOL', response.data.data);
			}
		})
		.catch((error) => {
			console.log(error);
			commit('SET_TOOL_LOADER', { id: '', active: false });
		});
};

export const clearTools = ({ commit, getters }) => {
	const tools = getters.workingTools;

	tools.forEach((item) => {
		setTimeout(() => {
			const index = tools.findIndex((tool) => tool.id === item.id);
			if (index !== -1) tools.splice(index, 1);
			commit('SET_RESOURCE_TOOLS', tools);
		}, 100);
		Document.deleteTool(item.id);
	});
};

export const removeTool = ({ commit, getters }, formData) => {
	const tools = getters.workingTools;
	const index = tools.findIndex((tool) => tool.id === formData.toolId);

	if (index !== -1) {
		tools.splice(index, 1);
	}
	commit('SET_RESOURCE_TOOLS', tools);
	socket.emit(events.NOTARY_DELETE_TOOLS, JSON.stringify(tools));

	Document.deleteTool(formData.toolId);
	if (formData.printId == undefined || formData.toolName != 'Textarea') return;
	Print.deletePrint(formData.printId);
};

export const getDocumentAuditTrails = ({ commit }, docId) => {
	commit('SET_AUDIT_TRAILS_LOADER', true);
	return Document.getDocumentAuditTrails(docId)
		.then((response) => {
			commit('SET_AUDIT_TRAILS', response.data.data);
			commit('SET_AUDIT_TRAILS_LOADER', false);
		})
		.catch((error) => {
			if (error) {
				commit('SET_AUDIT_TRAILS', []);
				commit('SET_AUDIT_TRAILS_LOADER', false);
			}
		});
};

export const updateDocumentAuditTrails = ({ commit }, data) => {
	commit('SET_AUDIT_TRAILS_UPDATE_LOADER', true);
	return Document.updateDocumentAuditTrails(data)
		.then(() => {
			commit('SET_AUDIT_TRAILS_UPDATE_LOADER', false);
		})
		.catch((error) => {
			if (error);
		});
};

// update audit for leave session
export const updateDocumentAuditTrailsLeaveSession = (_, data) => {
	return Document.updateDocumentAuditTrails(data)
		.then((response) => {
			if (response);
		})
		.catch((error) => {
			if (error);
		});
};

export const documentVerifySigners = ({ commit }, docId) => {
	commit('DOCUMENT_VERIFY_SIGNERS_LOADER', true);
	return Document.documentVerifySigners(docId)
		.then((response) => {
			commit('DOCUMENT_VERIFY_SIGNERS_LOADER', false);
			toast.success(`${response.data.data.message}`, {
				duration: 5000,
				position: 'top-right',
			});
			return true;
		})
		.catch((error) => {
			commit('DOCUMENT_VERIFY_SIGNERS_LOADER', false);
			toast.error(`${error.response.data.data.error}`, {
				duration: 5000,
				position: 'top-right',
			});
			return false;
		});
};

export const clearDoneEditing = ({ commit }) => {
	commit('SET_DOCUMENT_DONE', []);
};

export const clearCompleteSession = ({ commit }) => {
	commit('SET_SESSION_COMPLETE', false);
};


