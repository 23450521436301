export const token = (state) => {
	return state.token;
};

export const profile = (state) => {
	return state.profile;
};

export const loader = (state) => {
	return state.loader;
};

export const login_loader = (state) => {
	return state.login_loader;
};

export const signerPassword = (state) => {
	return state.change_signer_password;
};

export const verify_loader = (state) => {
	return state.verify_loader;
};

export const verify_error = (state) => {
	return state.verifyError;
};

export const verify_id_loading = (state) => {
	return state.verify_id_loading;
};

export const OTPFlag = (state) => {
	return state.OTPFlag;
};

export const documentOTP = (state) => {
	return state.documentOTP;
};

export const errors = (state) => {
	return state.errors;
};

export const is_notary = (state) => {
	return state.profile?.role[0] == 'Notary' ? true : false;
};

