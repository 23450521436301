export const time_slots = (state) => {
	return state.time_slots;
};
export const country = (state) => {
	return state.country;
};
export const states = (state) => {
	return state.states;
};
export const payment_gateways = (state) => {
	return state.payment_gateways;
};
export const transactionId = (state) => {
	return state.schedule_details?.transactions[0]?.id;
};

// export const transactions = (state) => {
//   return state.transactions;
// };

export const schedule_details = (state) => {
	return state.schedule_details;
};
export const hasMonetaryValue = (state) => {
	return state.hasMonetaryValue;
};
export const schedule_details_loading = (state) => {
	return state.schedule_details_loading;
};

export const virtual_session_details = (state) => {
	return state.virtual_session_details;
};

export const notaryStatusIsAcceptedorPending = (state) => {
	return (
		state?.virtual_session_details?.status === 'Accepted' ||
		state?.virtual_session_details?.status === 'Pending' ||
		state?.virtual_session_details?.status === 'Awaiting'
	);
};

export const session_details_loading = (state) => {
	return state.session_details_loading;
};

// agora
export const agora = (state) => {
	return state.agora;
};

export const initializedDocumentId = (state) => {
	return state.initializedDocumentId;
};



